import * as actions from '../actions/aircraft-list';
import * as models from '../../domain/models';
import * as _ from 'lodash';
import { AircraftSortingInput } from '../../../../shared/graphql-types';
import * as viewModels from '../../view/view-models';

export interface State {
  lookup: viewModels.AircraftLookup;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Aircraft>;
  search: string;
}

const initialState: State = {
  lookup: new viewModels.AircraftLookup(),
  isLoaded: false,
  isLoading: false,
  items: [],
  search: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_AIRCRAFT_LIST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case actions.LOAD_AIRCRAFT_LIST_SUCCESS: {
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = action.payload.count;
      return {
        ...state,
        lookup,
        items: action.payload.data,
        isLoaded: true,
        isLoading: false
      };
    }

    case actions.LOAD_AIRCRAFT_LIST_FAIL: {
      return {
        ...state,
        lookup: new viewModels.AircraftLookup(),
        items: [],
        isLoaded: true,
        isLoading: false
      };
    }

    case actions.SET_LOOKUP: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload)
      };
    }

    case actions.RESET_LOOKUP: {
      return {
        ...state,
        lookup: new viewModels.AircraftLookup()
      };
    }

    default: {
      return state;
    }
  }
}

export const getLookup = (state: State) => state.lookup;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getSearch = (state: State) => state.search;
