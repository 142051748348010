import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[Fleet Aircraft Categories Collection] Load';
export const SET_INITIAL = '[Fleet Aircraft Categories Collection] Set Initial';
export const LOAD_SUCCESS = '[Fleet Aircraft Categories Collection] Load Success';
export const LOAD_FAIL = '[Fleet Aircraft Categories Collection] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload {
  entities: Array<models.AircraftCategory>;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetInitialAction implements Action {
  readonly type = SET_INITIAL;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | SetInitialAction
  | LoadFailAction;
