import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD_AIRCRAFT_LIST = '[Fleet Aircraft List] Load Aircraft List';
export const LOAD_AIRCRAFT_LIST_SUCCESS = '[Fleet Aircraft List] Load Aircraft List Success';
export const LOAD_AIRCRAFT_LIST_FAIL = '[Fleet Aircraft List] Load Aircraft List Fail';
export const SET_LOOKUP = '[Fleet Aircraft List] Set Lookup';
export const RESET_LOOKUP = '[Fleet Aircraft List] Reset Lookup';

export class LoadAircraftListSuccessPayload {
  data: Array<models.Aircraft>;
  count: number;
}

export class LoadAircraftListAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST;
}

export class LoadAircraftListSuccessAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST_SUCCESS;
  constructor(public payload: LoadAircraftListSuccessPayload) {}
}

export class LoadAircraftListFailAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST_FAIL;
}

export class SetLookupAction implements Action {
  readonly type = SET_LOOKUP;
  constructor(public payload: viewModels.AircraftLookup) {}
}

export class ResetLookupAction implements Action {
  readonly type = RESET_LOOKUP;
}

export type Actions = LoadAircraftListAction
  | LoadAircraftListSuccessAction
  | LoadAircraftListFailAction
  | SetLookupAction
  | ResetLookupAction;
