import { AircraftSortingInput } from '../../../../shared/graphql-types';

export class AircraftLookup {
    searchTerm: string = null;
    skip = 0;
    limit = 20;
    total = 0;
    deleted: boolean = false;
    d085OpsSpecsApproval: boolean = true;
    sorting: AircraftSortingInput = { tailNumber: true };
    insApproveOnly: boolean = null;
    completed: boolean = null;
    approvedBy: string = null;
    aircraftCategory: number = null;
    homeBase: string = null;
    homeBaseCountry: string = null;
    homeBaseServiceAreas: Array<string> = null;
    onFleet: boolean = null;
    active = true;
    marketplacePhotosApproval: boolean = null;
    aircraftAge: number = null;
    refurbishmentAge: number = null;
  }